// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

    
a.btn-slideshow {
    background: $color-black;
    width: 260px;
    height: 78px;
    border-radius: 10px;
    transition: 0.3s;
    .text h5 {
        font-weight: 500;
        font-size: $font-size-16;
        color: $color-2;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        transition: 0.3s;
    }
    &:hover {
        background: transparent;
        border: 1px solid #F5FBF5;
        width: 255px;
        transition: 0.3s;
        .text h5 {
            color: $color-white;
            transition: 0.3s;
        }
    }
}
                
            

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text-num {
    padding: 75px 7%;
    .left-side {
        width: 60%;
        margin-right: 5%;
        h3 {
            font-weight: 500;
            font-size: $font-size-36;
            color: $color-1;
            line-height: 1.35;
            text-transform: initial;
        }
    }
    .right-side {
        .title {
            padding-bottom: 15px;
            h2 {
                font-weight: 600;
                font-size: $font-size-70;
                color: $color-1;
            }
        }
        h4 {
            font-weight: 500;
            font-size: $font-size-22;
            color: $color-1;
        }
    }
    @media screen and (max-width: 1250px) {
        flex-direction: column;
        .left-side {
            width: 100%;
            margin: 0 0 35px 0;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 65px 5%;
            .left-side {
                h3 {
                    font-size: 1.6rem;
                }
            }
            .right-side {
                flex-direction: column;
                width: 100%;
                text-align: center;
                .left {
                    margin: 0 0 25px 0;
                }
            }
            @media screen and (max-width: 375px) {
                .left-side {
                    h3 {
                        font-size: 1.5rem;
                    }
                }
                @media screen and (max-width: 360px) {
                    .left-side {
                        h3 {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

#section-video {
    iframe {
        width: 100% !important;
    }
    @media screen and (max-width: 1400px) {
        iframe {
            height: 700px !important;
        }
        @media screen and (max-width: 1100px) {
            iframe {
                height: 500px !important;
            }
        }
    }
}

#section-images {
    padding: 100px 7% 15px 7%;
    margin-top: -1px;
    position: relative;
    h3 {    
        font-weight: 600;
        font-size: $font-size-60;
        color: $color-2;
        line-height: 1.3;
        padding-bottom: 65px;
    }
    &::before {
        content: '';
        position: absolute;
        background: $color-black;
        height: 45%;
        width: 101%;
        top: -2px;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .image.accordion__item {
            font-size: 1rem;
            display: inline-block;
            vertical-align: top;
            height: 100%;
            min-height: 720px;
            box-sizing: border-box;
            position: relative;
            outline: none;
            background-size: cover;
            // border: 1px solid black;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 720px;
            &:nth-child(1) {
                background-image: url("../images/accueil_section03_img01.jpg");
            }
            &:nth-child(2) {
                background-image: url("../images/accueil_section03_img02.jpg");
            }
            &:nth-child(3) {
                background-image: url("../images/accueil_section03_img03.jpg");
            }
        }
    }
    p {
        padding: 25px 0 35px 0;
        width: 75%;
        line-height: 1.5;
    }
    @media screen and (max-width: 1440px) {
        h3 {
            font-size: 2.3rem;
            br {
                display: none;
            }
        }
        @media screen and (max-width: 1210px) {
            .slideshow-wrap {
                padding-top: 0;
            }
            @media screen and (max-width: $size-sm-max) {
                h3 {
                    font-size: 2rem;
                }
                p {
                    width: 90%;
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 75px 5% 50px 5%;
                    h3 {
                        text-align: center;
                        padding-bottom: 50px;
                    }
                    p {
                        width: 100%;
                    }
                }
            }
        }
    }
}

#section-logo {
    border-top: 1px solid #CACBD5;
    border-bottom: 1px solid #CACBD5;
    padding: 75px 0;
    margin: 0 7% 35px 7%;
    @media screen and (max-width: $size-xs-max) {
        flex-wrap: wrap;
        .logo {
            width: 100%;
            padding: 0 0 15px 0;
            .img-responsive {
                margin: auto;
            }
            &.px10 {
                padding: 15px 0 0 0;
            }
        }
    }
}

/*******************************************************************************
* PRODUITS & SERVICES
*******************************************************************************/

#section-empty {
    padding: 25px 0;
    @media screen and (max-width: 1200px) {
        padding: 0;
    }
}

#section-image-text {
    padding: 50px 7% 50px 0;
    .image-side {
        width: 55%;
        background: url("../images/produits_section01_img01.jpg") no-repeat;
        background-size: cover;
    }
    .text-side {
        width: 55%;
        margin-left: 5%;
        h3 {
            font-weight: 500;
            font-size: $font-size-36;
            color: $color-1;
            padding-bottom: 15px;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-20;
            color: $color-1;
            text-transform: uppercase;
            padding: 35px 0 10px 0;
        }
        ul {
            margin-left: 15px;
            li {
                font-weight: normal;
                font-size: $font-size-20;
                color: $color-1;
                text-transform: initial;
                line-height: 1.4;
                padding-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .image-side {
            background-position: center;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            padding: 50px 7%;
            .image-side {
                width: 70%;
                margin: 0 auto 35px auto;
            }
            .text-side {
                width: 100%;
                margin-left: 0;
                ul {
                    margin-left: 0px;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 50px 5%;
                    .image-side {
                        background: url("../images/produits_section01_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                    .text-side h3 br {
                        display: none;
                    }
                }
            }
        }
    }
}

#section-text-image {
    background: #666F8819;
    padding: 75px 0 75px 7%;
    .text-side {
        width: 55%;
        margin-right: 2%;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: #3D4251;
            padding-bottom: 25px;
        }
        h4 {
            font-weight: 500;
            font-size: $font-size-20;
            color: $color-1;
            text-transform: uppercase;
            line-height: 1.4;
            padding-bottom: 5px;
        }
        ol {
            margin-left: 20px;
            li {
                font-weight: normal;
                font-size: $font-size-20;
                color: $color-1;
                text-transform: initial;
                line-height: 1.4;
                padding-bottom: 10px;
            }
        }
    }
    .image-side {
        width: 45%;
        background: url("../images/produits_section02_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1440px) {
        .image-side {
            background-position: center;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            padding: 50px 7%;
            .image-side {
                width: 70%;
                margin: 0 auto 35px auto;
            }
            .text-side {
                width: 100%;
                margin-left: 0;
                ul {
                    margin-left: 0px;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 50px 5%;
                    .image-side {
                        background: url("../images/produits_section02_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                    .text-side h3 br {
                        display: none;
                    }
                }
            }
        }
    }
}

#section-image-textbox {
    padding: 50px 7%;
    .main-content {
        width: 50%;
        margin-right: 2%;
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0;
            }
        }           
    }
    .text-side {
        width: 50%;
        margin-top: 75px;
        h3 {
            font-weight: 500;
            font-size: $font-size-36;
            color: $color-1;
            line-height: 1.3;
            padding-bottom: 15px;
        }
        p {
            width: 81%;
        }
    }
    @media screen and (max-width: 1400px) {
        .text-side {
            margin-top: 25px;
        }
        @media screen and (max-width: 1130px) {
            flex-direction: column;
            .main-content {
                width: 70%;
                margin: 0 auto;
            }
            .text-side {
                width: 100%;
                p {
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .main-content {
                    width: 100%;
                }
            }
        }
    }
}

#section-produits {
    background: #040C040B;
    padding: 75px 7%;
    h4 {
        font-weight: 500;
        font-size: $font-size-36;
        color: $color-1;
        line-height: 1.3;
    }
    .section-text-image {
        padding-top: 50px;
        .left-side {
            width: 50%;
            margin-right: 2%;
            margin-top: 35px;
            h3 {
                font-weight: 500;
                font-size: $font-size-36;
                color: $color-1;
            }
            .section-list {
                padding-top: 35px;
                .left {
                    width: 50%;
                }
                .bloc {
                    h5 {
                        font-weight: normal;
                        font-size: $font-size-26;
                        color: $color-1;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .right-side {
            width: 50%;
            background: url("../images/produits_section01_img04.jpg") no-repeat;
            background-size: cover;
        }
    }
    @media screen and (max-width: 1465px) {
        h4 br {
            display: none;
        }
        .section-text-image {
            .left-side {
                margin-top: 0;
                .section-list {
                    .bloc {
                        margin: 0 0 15px 0;
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            .section-text-image {
                flex-direction: column;
               .left-side {
                    width: 100%;
                    margin: 0 0 25px 0;
                    .section-list .left {
                        width: 40%;
                    }
                }
                .right-side {
                    width: 70%;
                    margin: auto;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                 .section-text-image {
                   .left-side {
                        .section-list .left {
                            width: 45%;
                        }
                    }
                    .right-side {
                        width: 100%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 60px 5%;
                    .section-text-image {
                       .left-side {
                            .section-list {
                                flex-direction: column;
                                .left {
                                    width: 100%;
                                    margin-top: -10px;
                                }
                                .bloc {
                                    margin: 0;
                                }
                            }
                        }
                        .right-side {
                            background: url("../images/produits_section01_img04_m.jpg") no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* NOS PROCÉDÉS
*******************************************************************************/

#section-list {
    padding: 75px 7%;
    margin-top: 3px;
    background: $color-1;
    .left-side {
        width: 50%;
        margin-right: 5%;
    }
    .right-side {
        width: 50%;
    }
    .bloc {
        .text {
            width: 100%;
            h5 {
                font-weight: 500;
                font-size: $font-size-30;
                color: #F5FBF5;
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 75px 5%;
        flex-direction: column;
        .left-side {
            width: 100%;
            margin: 0 0 1.5rem 0;
        }
        .right-side {
            width: 100%;
        }
    }
}

#section-contenu {
    padding: 75px 7%;
    .section-text {
        .left-side {
            width: 55%;
            margin-right: 2%;
            ol {
                margin-left: 20px;
                li {
                    font-weight: normal;
                    font-size: $font-size-20;
                    color: $color-1;
                    text-transform: initial;
                    line-height: 1.4;
                    padding-bottom: 5px;
                }
            }
        }
        .right-side {
            width: 45%;
        }
        h3 {
            font-weight: 500;
            font-size: $font-size-36;
            color: $color-1;
            padding-bottom: 60px;
        }
    }
    .section-images {
        padding-top: 50px;
    }
    @media screen and (max-width: 1775px) {
        .section-text {
            .left-side {
                ol {
                    li br {
                        display: none;
                    }
                }
            }
        }
        @media screen and (max-width: 1550px) {
            .section-text {
                .left-side {
                    width: 50%;
                    margin-right: 5%;
                }
            }
            @media screen and (max-width: $size-md-max) {
                .section-images {
                    flex-wrap: wrap;
                    .image {
                        width: 45%;
                        margin: 0 0 15px 0;
                    }
                }
                @media screen and (max-width: 1170px) {
                    .section-text {
                        flex-direction: column;
                        .left-side {
                            width: 100%;
                            margin: 0 0 25px 0;
                        }
                        .right-side {
                            width: 100%;
                        }
                        h3 {
                            padding-bottom: 25px;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding: 75px 5%;
                        .section-images {
                            padding-top: 35px;
                            flex-direction: column;
                            .image {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-title-logo {
    padding: 50px 0;
    border-top: 1px solid $color-1;
    margin: 0 7%;
    .left-side {
        width: 50%;
        margin-right: 5%;
        h2 {
            font-weight: bold;
            font-size: $font-size-90;
            color: $color-1;
            text-transform: uppercase;
        }
    }
    .right-side {
        width: 50%;
    }
    @media screen and (max-width: 1790px) {
        .left-side {
            h2 {
                font-size: 4rem;
            }
        }
        .right-side {
            width: 35%;
        }
        @media screen and (max-width: 1510px) {
            .left-side {
                margin-right: 10%;
                h2 {
                    font-size: 3.4rem;
                }
            }
            .right-side {
                width: 35%;
            }
            @media screen and (max-width: 1150px) {
                flex-direction: column;
                .left-side {
                    margin: 0 0 25px 0;
                    width: 100%;
                    text-align: center;
                }
                .right-side {
                    width: 50%;
                    margin: auto;
                }
                @media screen and (max-width: $size-sm-max) {
                    .right-side {
                        width: 65%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        margin: 0 5%;
                        .left-side {
                            h2 {
                                font-size: 3rem;
                            }
                        }
                        .right-side {
                            width: 90%;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 25px 7% 50px 7%;
    .left-side {
        width: 70%;
        margin-right: 2%;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: $color-1;
        }
        .section-team {
            padding-top: 35px;
            .team {
                width: 50%;
                padding-bottom: 20px;
                .image {
                    width: 100%;
                    margin-right: -75px;
                }
                .text {
                    width: 100%;
                    margin-left: 15px;
                    h2 {
                        font-weight: 500;
                        font-size: $font-size-26;
                        color: $color-1;
                    }
                    h4 {
                        font-weight: normal;
                        font-size: $font-size-16;
                        color: #B5B5B5;
                        padding: 10px 0;
                    }
                }
                .mail {
                    .texte {
                        width: 100%;
                        h5 {
                            font-weight: 500;
                            font-size: $font-size-16;
                            color: $color-1;
                        }
                    }
                }
            }
        }
    }
    .right-side {
        width: 30%;
        background: #000;
        padding: 50px;
        h2 {
            font-weight: 500;
            font-size: $font-size-36;
            color: $color-2;
            padding-bottom: 50px;
        }
        .bloc {
            h4 {
                font-weight: normal;
                font-size: $font-size-26;
                color: $color-2;
                padding-bottom: 5px;
            }
            h5 {
                font-weight: normal;
                font-size: $font-size-18;
                color: #B5B5B5;
            }
        }
    }
    @media screen and (max-width: 1815px) {
        .left-side {
            .section-team {
                .team {
                    .image {
                        margin-right: -50px;
                    }
                }
            }
        }
        @media screen and (max-width: 1730px) {
            .left-side {
                .section-team {
                    .team {
                        .image {
                            margin-right: -25px;
                        }
                    }
                }
            }
            @media screen and (max-width: 1645px) {
                .left-side {
                    width: 75%;
                    .section-team {
                        .team {
                            .image {
                                margin-right: 0;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1595px) {
                    .left-side {
                        .section-team {
                            .team {
                                .mail .icon {
                                    display: none;
                                }
                            }
                        }
                    }
                    .right-side h2 {
                        font-size: 1.6rem;
                    }
                    @media screen and (max-width: 1460px) {
                        flex-direction: column-reverse;
                        .left-side {
                            width: 100%;
                            margin: 50px 0 0 0;
                            .section-team {
                                .team {
                                    .image {
                                        width: 70%;
                                    }
                                }
                            }
                        }
                        .right-side {
                            width: 100%;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            padding: 25px 5%;
                            .left-side {
                                .section-team {
                                    flex-direction: column;
                                    .team {
                                        width: 100%;
                                        padding-bottom: 35px;
                                        padding-right: 0;
                                        flex-direction: column;
                                        text-align: center;
                                        .image {
                                            width: 100%;
                                            .img-responsive {
                                                margin: auto;
                                            }
                                        }
                                        .text {
                                            margin: 15px 0 0 0;
                                            h4, .texte h5 {
                                                font-size: 1rem;
                                            }
                                        }
                                    }
                                }
                            }
                            .right-side {
                                padding: 50px 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-formulaire {
    padding: 75px 10%;
    height: 100%;
    h3 {
        font-weight: bold;
        font-size: $font-size-36;
        color: $color-1;
        width: 68%;
        padding-bottom: 15px;
    }
    @media screen and (max-width: 1600px) {
        h3 {
            width: 84%;
        }
        @media screen and (max-width: $size-md-max) {
            h3 {
                width: 92%;
            }
            @media screen and (max-width: 1024px) {
                margin-bottom: 110px;

                @media screen and (max-width: $size-sm-max) {
                    padding: 75px 7%;
                    margin-bottom: -150px;
                    h3 {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding: 75px 5%;
                        margin-bottom: 290px;
                        @media screen and (max-width: 375px) {
                            margin-bottom: 515px;
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 50px 7%;
    h4 {
        font-weight: 500;
        font-size: $font-size-40;
        color: $color-1;
        font-style: italic;
        padding-bottom: 50px;
    }
    @media screen and (max-width: 1400px) {
        h4 {
            font-size: 1.8rem;
        }
        @media screen and (max-width: $size-md-max) {
            #map-canvas {
                height: 550px !important;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 50px 5%;
            }
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: #B5B5B5;
    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/


@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
