footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 7%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .text-color-grey;
        @extend .font-footer;

        .menu-footer {
            width: 44%;
            a {
                font-weight: 500;
                font-size: $font-size-14;
                color: #CACBD5;
                text-transform: uppercase;
            }
        }

        .copyright-logo {
            .copyright {
                font-weight: normal;
                font-size: $font-size-16;
                color: #CACBD5;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 1565px) {
        .footer-wrapper {
            .menu-footer {
                a.pr10 {
                    padding-right: 1.5rem;
                } 
            }
        }
        @media screen and (max-width: 1200px) {
            min-height: 235px;
            .footer-wrapper {
                flex-direction: column;
                align-items: initial;
                .menu-footer {
                    padding-bottom: 0;
                    width: 100%;
                }
                .copyright-logo {
                    margin: 50px 0 0 0;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                min-height: 300px;
                .footer-wrapper {
                    .menu-footer {
                        flex-direction: column;
                        align-items: initial;
                        a.pr10 {
                            padding: 0 0 20px 0;
                        }
                    }
                }
            }
        }
    }
}
