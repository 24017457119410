.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 30%;
        left: 57%;
        width: 100%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        h2 {
            font-weight: bold;
            font-size: $font-size-80;
            color: $color-2;
            padding-bottom: 15px;
            text-transform: uppercase;
        }
        p {
          color: $color-2;
          margin-bottom: 75px;
        }
        @media screen and (max-width: 1600px) {
          top: 25%;
          @media screen and (max-width: 1400px) {
            top: 22%;
            h2 {
              font-size: 3.5rem;
            }
            @media screen and (max-width: 1150px) {
              top: 20%;
              h2 {
                font-size: 3.25rem;
              }
              @media screen and (max-width: 1060px) {
                top: 25%;
                p {
                  margin-bottom: 25px;
                }
                @media screen and (max-width: $size-sm-max) {
                  top: 13%;
                  left: 46%;
                  width: 80%;
                  h2 {
                    font-size: 3rem;
                  }
                  p {
                    br {
                      display: none;
                    }
                  }
                  @media screen and (max-width: $size-xs-max) {
                    top: 28%;
                    left: 50%;
                    width: 93%;
                    h2 {
                      font-size: 2.8rem;
                    }
                    a.btn-slideshow {
                      width: 210px;
                      height: 70px;
                      &:hover {
                          width: 205px;
                      }
                    }
                    @media screen and (max-width: 375px) {
                      top: 25%;
                      width: 92%;
                      h2 {
                          font-size: 2.6rem;
                      }
                      @media screen and (max-width: 360px) {
                        top: 23%;
                        width: 92%;
                        h2 {
                          font-size: 2.5rem;
                        }
                        p br {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }

    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
}
