.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 60%;
        left: 7%;
        width: 100%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            font-weight: bold;
            font-size: $font-size-80;
            color: $color-2;
            padding-bottom: 15px;
            text-transform: uppercase;
        }
        &--subtitle {
            width: 47%;
            font-weight: normal;
            font-size: $font-size-20;
            color: $color-2;
            line-height: 1.5;
            margin-bottom: 50px;
        }
    }
}
@media screen and (max-width: 1600px) {
    .module-header {
        &--caption {
            top: 57%;
        }
    }
    @media screen and (max-width: 1535px) {
        .module-header {
            &--caption {
                &--subtitle {
                    width: 58%;
                }
            }
        }
        @media screen and (max-width: 1400px) {
            .module-header {
                &--caption {
                    &--title {
                        font-size: 3.5rem;
                    }
                    &--subtitle {
                        width: 56%;
                    }
                }
            }
            @media screen and (max-width: 1165px) {
                .module-header {
                    &--caption {
                        top: 53%;
                        &--title {
                            font-size: 3.25rem;
                        }
                        &--subtitle {
                            width: 65%;
                        }
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .module-header {
                        &--caption {
                            top: 53%;
                            &--title {
                                font-size: 3rem;
                            }
                            &--subtitle {
                                width: 80%;
                                margin-bottom: 25px;
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .module-header {
                            &--caption {
                                top: 61%;
                                left: 5%;
                                &--title {
                                    font-size: 2.8rem;
                                    br {
                                        display: none;
                                    }
                                }
                                &--subtitle {
                                    width: 80%;
                                    margin-bottom: 25px;
                                }
                                a.btn-slideshow {
                                    width: 210px;
                                    height: 70px;
                                    &:hover {
                                        width: 205px;
                                    }
                                }
                            }
                        }
                        @media screen and (max-width: 375px) {
                            .module-header {
                                &--caption {
                                    top: 58%;
                                    &--title {
                                        font-size: 2.6rem;
                                    }
                                }
                            }
                            @media screen and (max-width: 360px) {
                                .module-header {
                                    &--caption {
                                        &--title {
                                            font-size: 2.55rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}