.btn {
    background-color: $color-black;
    border: 1px solid $color-black;
    color: #F5FBF5;
    border: none;
    border-radius: 10px;
    height: 80px;
    width: 220px;

    font-weight: 500;
    font-size: $font-size-18;
    letter-spacing: 3.6px;
    transition: all 0.2s ease-in;


    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-white;
        color: $color-1;
        border: 1px solid #707070;
    }
}
